import React from "react"

class DrupalNodeBody extends React.Component {

  render() {
     let node = this.props.relatedNode;
     var body = null;
     if (node.body) {
        if (node.body.processed) {
                body = node.body.processed;
        }
     };

     if (body) {
         return (
          <div dangerouslySetInnerHTML={{ __html: body }}></div>
         );
    } else {
        return ( <div></div> );
    }

  }
}

export default DrupalNodeBody 
