import React from "react"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import DrupalNodeBody from "../components/drupal-node-body"

class Sections extends React.Component {
    render () {

     let node = this.props.relatedNode;
     let nodeCardSection = node.relationships.node__card_section;

     if (nodeCardSection) {
         return (
          <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
            {nodeCardSection.map((card_section) => (
                    <AccordionItem key={card_section.id}>
                       <AccordionItemHeading>
                            <AccordionItemButton>{ card_section.title }</AccordionItemButton>
                       </AccordionItemHeading>
                       <AccordionItemPanel>
			  <DrupalNodeBody relatedNode={card_section}  />
                       </AccordionItemPanel>
                    </AccordionItem>
            ))}
          </Accordion>
         );
    } else {
        return ( <div></div> );
    }
  }
}

export default Sections
