import React from "react"

class NodeDescription extends React.Component {
    render () {

     let node = this.props.relatedNode;
     var description = null;
     if (node.description) {
	if (node.description.processed) {
		description = node.description.processed;
	}
     };

     if (description) {
         return (
	  <div dangerouslySetInnerHTML={{ __html: description }}></div>
         );
    } else {
        return ( <div></div> );
    }
  }
}

export default NodeDescription 
