import React from 'react';
import { graphql } from 'gatsby';
import Layout from "../components/layout";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import Sections from "../components/sections";
import NodeDescription from "../components/node-description";

import '../components/accordion.css';

export default ({ data }) => {
    if (data.allTaxonomyTermCards.edges) {
   
       return (
       <Layout>
        <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
	    {data.allTaxonomyTermCards.edges.map(({ node }) => (
              <AccordionItem key={node.id}>
          	<AccordionItemHeading>
		  <AccordionItemButton> { node.name } </AccordionItemButton>
          	</AccordionItemHeading>
          	<AccordionItemPanel>
		  <NodeDescription relatedNode={node}/>
		  <Sections relatedNode={node}/>
               </AccordionItemPanel>
             </AccordionItem>
            ))}
        </Accordion>
       </Layout>
       )
    } else {
	return (
	<Layout>
	  <div>No Results.</div>
	</Layout>
	)
    }
}

export const query = graphql`
  query {
     allTaxonomyTermCards (sort: {fields: weight, order: ASC}) {
         edges {
           node {
             name
	     description {
		processed
	     }
             id
             relationships {
                 node__card_section {
                   id
                   title
                   body {
                      processed
                   }
                 }
             }
          }
        }
     }
  }
`
